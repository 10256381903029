import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/index.vue'
import HomeView from '../views/HomeView.vue'
import products from '../views/products.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'HomeView',
        component: HomeView,
      },
      {
        path: '/products',
        name: 'products',
        component: products,
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/profile.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
