<template>
  <div class="core">
    <div class="core-item" v-for="item in coreList" :key="item.name">
      <i :class="item.iconfont"></i>
      <span class="core-tit">{{item.name}}</span>
      <span class="core-text">{{item.text}}</span>
    </div>
  </div>
</template>

<script setup>
const coreList = [
  { name: "云计算实施", iconfont: "iconfont icon-yunjisuan-", text: "协助企业云技术部署，推动IT系统云迁移，致力于未来业务的云化运营" },
  { name: "物联网平台与应用", iconfont: "iconfont icon-wulianwang", text: "为企业特定业务设计智能方案，包括传感、数据整合和平台运营全流程" },
  { name: "软件工程技术", iconfont: "iconfont icon-xitongguanli", text: "提升企业内部IT系统在敏感性、弹性和开发运维一体化方面的能力" },
  { name: "人工智能应用方案", iconfont: "iconfont icon-rengongzhineng", text: "运用人工智能构建智能市场运营、智能系统运维和智能企业管理方案" },
]

</script>
<style lang='scss' scoped>
.core {
  padding: 0 300px;
  display: flex;
  justify-content: center;
  .core-item {
    flex: 0.25;
    display: flex;
    flex-direction: column;
    align-items: center;
    .core-tit {
      margin-top: 20px;
      font-size: 18px;
      font-weight: bold;
    }
    .core-text {
      margin-top: 10px;
      width: 200px;
      color: #7c7d80;
      font-size: 14px;
    }
    .iconfont {
      font-size: 80px;
      color: #409eff;
    }
  }
}

@media screen and (max-width: 1700px) {
  .core {
    padding: 0 100px;
  }
}

@media screen and (max-width: 1400px) {
  .core {
    padding: 0 60px;
  }
}

@media screen and (max-width: 900px) {
  .core {
    display: flex !important;
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
    padding: 0 10px;
    .core-item {
      flex: 1 !important;
      width: 50% !important;
      margin-bottom: 20px;
      .core-tit {
        font-size: 16px !important;
      }
    }
    .iconfont {
      font-size: 40px !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .core {
    .core-item {
      flex: 1 !important;
      width: 100% !important;
    }
  }
}
</style>