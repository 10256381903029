<template>
  <el-menu :default-active="defaultActiveMenu" mode="horizontal" :style="{ background: bgColor }" router
    :ellipsis="false">
    <router-link type='div' class="logo" to='/home'>
      <img src="@/assets/logo.jpg" />
      <h3 :style="{ color:fzColor }">
        <span class="screen-width-min">Asiainfo Vanlink </span>
        <span class="screen-tit"> 亚信万联</span>
      </h3>
    </router-link>
    <div class="flex-grow" />
    <div class="link-router">
      <el-menu-item :style="{ color:fzColor }" index="/home">首页</el-menu-item>
      <el-menu-item :style="{ color:fzColor }" index="/products">产品案列</el-menu-item>
      <el-menu-item :style="{ color:fzColor }" index="/profile">关于我们</el-menu-item>
      <span class="menu-item screen-tit"> | </span>
      <div class="menu-link screen-tit">
        <el-popover placement="top-start" :width="260" trigger="hover" popper-class="popover">
          <div class="van-dm" v-for="item in skiplList" :key="item.name" @click="onRouterLine(item.connected)">
            <i :class="item.icon"></i>
            <div class="dm">
              <span class="dm-tit"> {{ item.ele }} </span>
              <span class="dm-label">{{ item.name }}</span>
            </div>
          </div>
          <template #reference>
            <span> 产品体验 <i class="iconfont icon-xiangxiajiantou"></i> </span>
          </template>
        </el-popover>
      </div>
    </div>

  </el-menu>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";


const skiplList = [
  {
    name: "物联设备管理云平台",
    ele: "VanDM",
    icon: "iconfont icon-wulianwang",
    connected: "https://cloudtest.aivanlink.com/",
  },
  {
    name: "能耗管理系统",
    ele: "VanEMS",
    icon: "iconfont icon-nenghaochengben",
    connected: "https://cloudtest.aivanlink.com/iotx/ems",
  },
];

// const skiplVisible = ref(false);

// const activeIndex = ref("/home");
const router = useRoute();
const defaultActiveMenu = computed(() => {
  const { meta, path } = router;
  return meta?.activeMenu || path;
});

const bgColor = ref("linear-gradient(to right, #13557e, #13557e)");
const fzColor = ref("#fff"); // 初始背景色
// const items = ref(
//   Array.from({ length: 100 }, (_, index) => ({ id: index + 1 }))
// );
// const { meta, path } = router;
// const handleScroll = () => {
//   if (path == "/home") {
//     const scrollPosition = window.scrollY || window.pageYOffset;
//     // 根据滚动条位置设置背景色
//     if (scrollPosition > 500) {
//       bgColor.value = "#fff";
//       fzColor.value = "#000";
//     } else {
//       bgColor.value = "linear-gradient(to right, #13557e, #13557e)";
//       fzColor.value = "#fff";
//     }
//   }
// };

// const targetElement = ref(null);

const onRouterLine = (val) => {
  // console.log(val);
  window.open(val, "_blank");
};
// const goAnchor = (target) => {
//   EventBus.emit("scroll-to-target", target);
//   activeLink.value = target;
// };

// const activeLink = ref(null);

// onMounted(() => {
//   // 监听滚动事件
//   window.addEventListener("scroll", handleScroll);
//   // goAnchor("home");
// });
</script>

<style lang='scss' scoped>
.el-menu {
  border-bottom: none !important;
  .el-menu-item.is-active {
    background-color: transparent !important;
    border-bottom: 2px solid #03ffd5 !important;
    color: #03ffd5 !important;
  }
  .el-menu-item:hover {
    background-color: transparent !important;
    color: #409eff !important;
  }
  .flex-grow {
    flex-grow: 1;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 200px;
    text-decoration: none;
    color: #fff !important;
    img {
      width: 120px;
      height: 100%;
      margin-right: 20px;
    }
  }
  .link-router {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 300px;
    color: #fff;
    .menu-item {
      display: inline-block;
      padding: 0px 15px 3px 0px;
    }
    .menu-link {
      font-size: 14px;
    }
    .menu-link:hover {
        color: #409eff !important;
    }
  }
  .icon-xiangxiajiantou {
    font-size: 12px;
  }
}
.van-dm {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  color: #000;
  cursor: pointer;
  i {
    font-size: 40px;
    margin-right: 10px;
    color: #fff;
  }
  .dm {
    flex: 1;
    display: flex;
    flex-direction: column;
    .dm-tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
      color: #fff;
    }
    .dm-label {
      font-size: 14px;
      color: #e5e5e5;
    }
  }
}
.van-dm:hover {
    background-color: #409eff;
    color: #ffffff !important;
}

@media screen and (max-width: 1400px) {
  .link-router {
    padding-right: 100px !important;
  }
  .logo {
    padding-left: 100px !important;
  }
}

@media screen and (max-width: 1200px) {
  .link-router {
    padding-right: 0 !important;
  }
  .logo {
    padding-left: 20px !important;
  }
}

@media screen and (max-width: 800px) {
  .screen-width {
    display: none;
  }
  .logo {
    padding-left: 20px !important;
    h3 {
      font-size: 16px !important;
    }
    img {
      width: 60px !important;
      margin-right: 0 !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .screen-width-min {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .screen-tit {
    display: none !important;
  }
}
</style>