<template>
  <div class="advantages">
    <div class="advantages-item" v-for="item in advantagesList" :key="item.name">
      <div class="back-icon">
        <i :class="item.icon"></i>
      </div>
      <div class="advantages-info">
        <div class="advantages-tit">{{item.name}}</div>
        <span class="advantages-text">{{item.text}}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
const advantagesList = [
  { name: '高质量交付', icon: "iconfont icon-jiangpai", text: "高质量交付，超越期望，为客户提供卓越物联网解决方案" },
  { name: '一站式服务', icon: "iconfont icon-KJ_012", text: "全程服务，从规划设计到升级维护，我们一手包办，确保项目成功与可持续发展" },
  { name: '卓越的技术实力', icon: "iconfont icon-tuandui", text: '专业技术，解决复杂需求，为客户提供高效可靠的物联网解决方案' },
  { name: '安全与可靠性保障', icon: "iconfont icon-anquanyinsi", text: "通过先进技术和稳定架构，确保物联网系统安全可靠，保障数据隐私和系统稳定运行" },
]

</script>
<style lang='scss' scoped>
.advantages {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 300px;
  .advantages-item {
    width: calc(50% - 100px);
    background-color: #fff;
    border-radius: 10px;
    height: 160px;
    margin: 5px;
    display: flex;
    align-items: center;
    padding: 0 40px;
    .back-icon {
      padding: 10px;
      border-radius: 50%;
      background-color: #f7f7f9;
      display: flex;
      justify-content: center;
      align-items: center;
      height: fit-content !important;
      .iconfont {
        font-size: 60px;
        color: #409eff;
      }
    }
    .advantages-info {
      margin-left: 20px;
      .advantages-tit {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .advantages-text {
        display: block;
        // text-indent: 2em;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .advantages {
    padding: 0 100px;
  }
  .advantages-text {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1200px) {
  .advantages {
    padding: 0 50px;
  }
  .advantages-item {
    width: 100% !important;
    height: 100px !important;
  }
  .advantages-text {
    font-size: 14px !important;
  }
  .back-icon {
    .iconfont {
      font-size: 40px !important;
    }
  }
}
@media screen and (max-width: 800px) {
  .advantages-item {
    height: 120px !important;
    padding: 0 20px !important;
  }
  .advantages {
    padding: 0 10px;
  }
  .advantages-text {
    font-size: 12px !important;
  }
  .advantages-tit {
    margin-bottom: 10px !important;
    font-size: 16px !important;
  }
}
</style>