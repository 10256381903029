import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import '@/common/icon/iconfont.css'
import '@/common/scss/base.scss'
import '@/common/iconfont/iconfont.css'
import ElementPlus from 'element-plus'
import { ElMessage } from 'element-plus'
import 'element-plus/dist/index.css'

createApp(App).use(store).use(router).use(ElementPlus).provide('$message', ElMessage).mount('#app')
