<template>
  <router-view />
</template>

<script setup>
import { inject, defineComponent, onMounted } from 'vue';
// import { ElMessage } from 'element-plus'
document.addEventListener("contextmenu", (event) => {
  event.preventDefault();
  // ElMessage.warning('禁止使用右击');
});
</script>
<style lang='scss'>
body {
  margin: 0 !important;
  padding: 0 !important;
  .title-label {
    font-size: 24px;
    font-weight: bold;
    padding: 40px 20px;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  // .title-label::before {
  //   content: "";
  //   display: inline-block;
  //   width: 6px;
  //   height: 26px;
  //   background: #409eff;
  //   margin-right: 10px;
  //   border-radius: 2px;
  // }
}
@media screen and (max-width: 500px) {
  .title-label {
    font-size: 20px !important;
  }
}
</style>