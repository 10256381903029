<template>
  <div class="login" :style="{ backgroundImage: 'url(' + loginLeft + ')' }">
    <div class="login-form-container">
      <div class="logo-text">
        <span>亚信万联，万物互联</span>
      </div>
    </div>
  </div>
</template>
<script setup>
const loginLeft = require('@/assets/bg/7.jpg');
</script>
<style scoped lang="scss">
@import "@/common/scss/van.scss";
</style>


