<template>
  <div class="login" :style="{ backgroundImage: 'url(' + loginLeft + ')' }">
    <div class="login-form-container">
      <div class="logo-text">
        <span>聚合数字化人才和技术</span>
        <span>助力企业数字化化转型</span>
        <span class="mar-labe label-text">云服务专家</span>
        <span class="label-text">场景运营专家</span>
      </div>
    </div>
  </div>
</template>
<script setup>
const loginLeft = require('@/assets/bg/6.png');
</script>
<style scoped lang="scss">
@import "@/common/scss/ems.scss";
</style>


