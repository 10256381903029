<template>
  <div class="carousel">
    <div class="carousel-left">
      <div class="item-name" v-for="(item, index) in carouselList" :key="item.name"
        :class="{ 'hovered': hoveredIndex === index }" @mouseover="handleMouseOver(index)"
        @mouseleave="handleMouseLeave" @click="handleMouseOver(index)">
        <i :class="item.icon"></i>
        <span>{{ item.ele }}</span>
      </div>
    </div>
    <div class="carousel-right">
      <img class="img-left" src="@/assets/login/login-form-left.png" alt="">
      <img class="img-right" src="@/assets/login/login-form-right.png" alt="">
      <h2> {{ carouselList[hoveredIndex].name }} </h2>
      <span class="carousel-desc">{{ carouselList[hoveredIndex].auxiliary }}</span>
      <div class="carousel-list">
        <div :class="carouselList[hoveredIndex].listText.length > 4 ? 'list-item' : 'list-item-small' "
          class="item-info" v-for="item in carouselList[hoveredIndex].listText" :key="item.text">
          <div class="item-tit">
            <i :class="item.iconfont"> </i>
            <div>{{ item.tit }}</div>
          </div>
          <span class="item-text">{{ item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
const carouselList = [
  {
    name: "VanDM物联设备管理云平台", ele: "VanDM", icon: 'iconfont icon-wulianwang', auxiliary: "让您专注于行业应用与生态构建，平台为物联网场景化业务的快捷运营提供有力支撑",
    listText: [
      { text: "支持MQTT、Modbus等标准协议，同时支持对私有协议的灵活通配，满足快速接入需要", tit: "设备快速接入", iconfont: 'iconfont icon-shebeijieru' },
      { text: "使用产品物模型来抽象和定义设备，几乎适配泛行业所有设备，大幅减少建设成本，并实现对设备统一监控和管控", tit: "统一设备模型", iconfont: 'iconfont icon-tongyirenzheng_0' },
      { text: "灵活的规则配置，可实现多变的业务场景定义，快速运营变现", tit: "规则引擎", iconfont: 'iconfont icon-shujuguize' },
      { text: "平台采用了敏捷架构充分考虑了业务的成长性，可随业务量的增长，快速扩展处理性能", tit: "敏捷架构", iconfont: 'iconfont icon-zuzhijiagou' },
      { text: "可对设备进行灵活分组，比如空间、组织等，满足多维度多角度运维需要", tit: "设备分组", iconfont: 'iconfont icon-KJ_012' },
      { text: "提供专家定制服务，为场景实现提供最佳的平台能力个性定制，从而保障最优的投入产出比", tit: "专家定制", iconfont: 'iconfont icon-xitong-' },
    ]
  },
  {
    name: "VanDE物联设备边缘系统", ele: "VanDE", icon: 'iconfont icon-icon_shujufuwu', auxiliary: "重点关注数据隐私和安全，缩短响应时延，并减少云厂商依赖，可在本地部署边缘系统",
    listText: [
      { text: "可在本地对所有设备的状态和数据进行监控和管理，确保在离网场景下也可以自运行", tit: "设备本地管控", iconfont: 'iconfont icon-tiaoduguanli' },
      { text: "数据经过本地汇聚和处理之后，可再同步给云端，减少网络传输成本和云端计算处理", tit: "数据本地汇聚和处理", iconfont: 'iconfont icon-yidongyunkongzhitaiicon58' },
      { text: "聚焦小场景，灵活配置规则，可实现阈值监控、数据转换转发、设备间联动等", tit: "规则引擎", iconfont: 'iconfont icon-shujuguize' },
      { text: "边缘和云端分工明确，可在云端存储分析数据，管理和下发系统配置、控制规则等", tit: "云边协同", iconfont: 'iconfont icon-icon-test' },
      { text: "提供现场实时控制和管理界面，并可以和其他内部系统协同作业，具备现场实时监控报警能力", tit: "构建本地应用", iconfont: 'iconfont icon-bendishangchuan' },
      { text: "专家根据需求定制软硬件整体方案，快速打造创新硬件产品，如动环监测设备、自助柜等", tit: "定制软硬件整体方案", iconfont: 'iconfont icon-pingtai' },
    ]
  },
  {
    name: "VanEMS能耗管理系统", ele: "VanEMS", icon: 'iconfont icon-nenghaochengben', auxiliary: "系统对水、电、气等能源的使用进行监测和分析，解决企业高耗能低效率问题，提升能源的可靠性和使用效率，实现节能减排和安全高效生产",
    listText: [
      { text: "系统实时监测电、水、气表具状态和数据，对异常状态和数据及时报警", tit: "实时监测", iconfont: 'iconfont icon-zaixianjiance' },
      { text: "对能耗使用进行周期性统计查询【实时、小时、日、周、月、季、年】；同时支持多维度【同环比、极值、需量等】按周期查询", tit: "周期性统计查询", iconfont: 'iconfont icon-shijianzhouqi' },
      { text: "深度整合组织、区域纬度分析，方便管理人员洞察企业运营缺陷和不足", tit: "组织、区域纬度分析", iconfont: 'iconfont icon-fenxi' },
      { text: "定期生成能耗周期报告【如周、月报等，PDF格式】，汇总能耗数据，深度分析系统运行、能耗使用等情况，并提供相关合理化建议", tit: "用能周期报告", iconfont: 'iconfont icon-baogao' },
      { text: "提供个性化用能活动的能耗分析，更细粒度支撑企业规范化运营", tit: "用能活动", iconfont: 'iconfont icon-qiyeyongnengfenxi' },
      { text: "系统集成了租赁业务中能耗相关的抄表、计费、收费等便捷功能，可配置灵活的个性化费率，改善租户用能体验，挖掘租赁潜力，进一步助力企业运营支撑", tit: "集成部分租赁业务", iconfont: 'iconfont icon-fangwuzulin' },
    ]
  },
  {
    name: "VanIIA工业互联网主动标识解析系统", ele: "VanIIA", icon: 'iconfont icon-secure-payment', auxiliary: "系统由设备、VanDG【可选】、VanDM、标识认证解析子系统组成，让设备具备唯一可信身份，进入国家工业互联网标识体系，可充分挖掘运营场景想象空间（如智慧工地监管、抵押物监控等）",
    listText: [
      { text: "VanDG集成了多协议支持和工业主动标识，让哑设备或旧设备也具备唯一可信身份，极速接入VanDM，部署便捷高效", tit: "VanDG【可信网关】", iconfont: 'iconfont icon-VPNwangguan' },
      { text: "提供携带工业标识的定制芯片，让设备在出厂前就预置了身份信息，加电后即可完成自动注册和认证过程，方便快捷", tit: "定制主动标识芯片", iconfont: 'iconfont icon-cpufuwuicon' },
      { text: "可开启数据传输加密，对部分重点数据或完整数据进行加密后传输，避免数据造假、被篡改和被拦截", tit: "数据传输加密", iconfont: 'iconfont icon-wangluoanquan' },
      { text: "VanDG和定制芯片的工业标识可选择软标识或硬标识，根据具体业务场景个性化选择最佳方案", tit: "软标识和硬标识", iconfont: 'iconfont icon-shenfenzheng' },
    ]


  },
]
const hoveredIndex = ref(0)
const handleMouseOver = (index) => {
  hoveredIndex.value = index;
}


</script>
<style lang='scss' scoped>
.carousel {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  .carousel-left {
    background-color: #13557e;
    .item-name {
      padding: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      color: #fff;
      .iconfont {
        font-size: 40px;
        margin-bottom: 20px;
      }
      span {
        font-weight: bold;
      }
    }
    .hovered {
      background-color: #409eff;
    }
  }
  .carousel-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* margin-left: 10px; */
    background-color: #f7f7f7;
    padding: 20px 60px;
    position: relative;
    .carousel-list {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 20px;
      .item-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        .item-tit {
          display: flex;
          align-items: center;
          font-size: 18px;
          margin-bottom: 10px;
          color: #4b4a4a;
          .iconfont {
            font-size: 28px;
            margin-right: 10px;
            color: #409eff;
          }
          div {
            font-weight: bold;
          }
        }
        .item-text {
          flex: 1;
          font-size: 14px;
          color: #666;
        }
      }
      .list-item {
        width: calc(33% - 40px);
      }
      .list-item-small {
        width: calc(50% - 40px);
      }
    }
    .img-left,
    .img-right {
      position: absolute;
      top: 0;
      width: 300px;
      height: 100%;
    }
    .img-left {
      left: 0;
    }
    .img-right {
      right: 0;
    }
  }
}

@media screen and (max-width: 500px) {
  .carousel {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 10px;
    .carousel-left {
      display: flex !important;
      justify-content: space-between !important;
      .item-name {
        padding: 5px !important;
        .iconfont {
          font-size: 20px !important;
          margin-bottom: 10px !important;
        }
      }
    }
    .carousel-right {
      padding: 20px 0 !important;
      margin-left: 0 !important;
      h2 {
        font-size: 20px !important;
      }
      .list-item,
      .list-item-small {
        width: 100% !important;
      }
    }
  }
}
</style>


